.boxGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  padding: 0;
  margin: 0;
}

.boxSection3,
.boxSection4 {
  flex-direction: row-reverse;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
  padding: 0.8rem;
  color: var(--mainBlue);
}

.box p {
  margin: 0.7rem 0;
  line-height: 1.3rem;
  text-align: justify;
}

.box img {
  width: 100%;
}

.box a {
  text-decoration: none;
}

.box .repoIcons {
  color: white;
  font-size: 2rem;
}

.repoIcons:hover {
  font-size: 2.1rem;
}

.title {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  height: 3.5rem;
  /* padding: 0.7em 1em; */
  text-align: center;
  background-color: var(--mainBlue);
  border-radius: 0.3rem;
  margin: 2rem auto;
}

.title a {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--pink);
}

.title .projectLink {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-thickness: 0.2rem;
  text-decoration-color: var(--brightAttention);
}

.projectLink:hover {
  font-weight: bolder;
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-thickness: 0.2rem;
  text-decoration-color: var(--pink);
  color: var(--green);
}

@media (max-width: 1450px) {
  .box p {
    line-height: 1rem;
  }
}

@media (max-width: 1200px) {
  .title {
    width: 80%;
    font-size: 0.8rem;
  }
}

@media (max-width: 1024px) {
  .boxGroup {
    width: 90%;
  }

  .boxSection2,
  .boxSection6 {
    flex-direction: row-reverse;
  }

  .boxSection3 {
    flex-direction: row;
  }
  .title {
    width: 65%;
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 560px) and (max-width: 768px) {
  .title {
    width: 100%;
  }
}

@media (max-width: 580px) {
  .boxGroup {
    flex-direction: column;
    justify-content: center;
  }

  .box {
    width: 80%;
    padding: 0;
    justify-content: center;
  }
  .box p {
    display: none;
  }

  .title {
    width: 45%;
    margin-top: 2rem;
    margin-bottom: -1rem;
    z-index: 1;
  }
}

@media (max-width: 480px) {
  .title {
    width: 80%;
  }
}
