:root {
  --mainBlue: #1E4D7B;
  --grey: #D3D3D3;
  --pink: #FF6B61;
  --green: #679267;
  --brightAttention: #FFC107;
}

@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Nothing+You+Could+Do&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  line-height: 1;
  font-family: 'Comfortaa', cursive;
  background-color: var(--grey);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

input,
select,
option,
optgroup,
textarea,
button,
pre,
code {
  font-size: 100%;
  font-family: inherit;
}

ol,
ul {
  list-style: none;
}

.pink {
  font-weight: bolder;
  color: var(--pink);
}