#pageFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    font-size: 3rem;
    line-height: 1.2em;
    font-weight: bolder;
    text-align: center;
    background-color: var(--pink);
    color: var(--mainBlue);
}

.footerClick {
    text-decoration: none;
    color: var(--brightAttention);
}

.footerClick:hover{
    color: var(--green);
}

@media (max-width: 1200px) {
    #pageFooter {
        padding: 1rem;
        font-size: 2rem;
    }
}

@media (max-width: 1024px) {
    #pageFooter {
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {
    #pageFooter {
        padding: 1em;
    }
}

@media (max-width: 480px) {
    #pageFooter {
        padding: 1.5rem;
    }

    #pageFooter p {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
}
