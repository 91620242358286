#aboutMe {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 3rem 15rem;
    color: var(--mainBlue);
}

#aboutMe h1,
#aboutMe h2,
#aboutMe div {
    width: 30%;
    height: auto;
    white-space: nowrap;
}

#aboutMe h1 {
    margin: auto 0;
    font-size: 2.5em;
}


#aboutMe h2 {
    margin-top: auto;
    width: 50%;
    font-size: 2em;
    text-align: right;
}

#aboutMe div:first-of-type {
    margin: auto 0;
    white-space: normal;
}

#aboutMe p {
    font-size: 1.5em;
}

#aboutMe img {
    width: 15%;
    border: .3rem solid var(--mainBlue);
    border-radius: 50%;
}

#aboutMe .right {
    text-align: right;
}

#aboutMe .left {
    text-align: left;
}

#aboutMe .info {
    width: 100%;
    margin: 3rem auto;
    text-align: justify;
    line-height: 1.7rem;
}

.aboutLink {
    color: var(--green);
    text-decoration: none;
}

.aboutLink:hover{
    color: var(--pink);
    text-decoration: underline;
    text-decoration-color: var(--green);
    font-weight: bold;

}
@media (max-width: 1200px) {

    #aboutMe {
        padding: 0 7rem;
    }

}

@media (max-width: 1024px) {

    #aboutMe h1 {
        font-size: 2.5rem;
    }

    #aboutMe h2 {
        font-size: 2rem;
    }

    #aboutMe p {
        font-size: 1.3rem;
    }

}

@media (max-width: 768px) {

    #aboutMe {
        padding: 0 3rem .5rem;
    }

    #aboutMe h1 {
        margin-bottom: 0;
        font-size: 1.4rem;
    }

    #aboutMe h2 {
        font-size: 1rem;

    }

    #aboutMe p {
        font-size: .7rem;
    }

    #aboutMe img {
        width: 30%;
    }

}

@media (min-width: 560px) and (max-width: 768px) {

    #aboutMe h1 {
        font-size: 2rem;
    }
    
    #aboutMe h2 {
        font-size: 1.5rem;

    }

    #aboutMe p {
        font-size: 1rem;
    }

}

@media (max-width: 480px) {

    #aboutMe {
        flex-direction: column;
    }

    #aboutMe h1 {
        font-size: 1.5rem;
    }

    #aboutMe h2 {
        align-self: flex-end;
        font-size: .9rem;
    }

    #aboutMe img {
        align-self: center;
        border: .2em solid var(--mainBlue);
        width: 50%;
    }

    .hiddenAbout {
        display: none;
    }

}